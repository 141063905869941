import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { User } from "../type";

class UserService {
  static async create(username: string, password: string, config: AxiosRequestConfig): Promise<number> {
    try {
      const response = await axios.post<number>(`${process.env.REACT_APP_MAIN_URL || ""}user`, { username, password }, config);
      return response.data;
    } catch (error) {
      // @ts-ignore
      throw { message: error.response?.data ?? error.message, status: error.response?.status };
    }
  }
  static async delete(id: string, config: AxiosRequestConfig): Promise<void> {
    try {
      await axios.delete<void>(`${process.env.REACT_APP_MAIN_URL || ""}user/${id}`, config);
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
  static async update(id: string, username: string, password: string, config: AxiosRequestConfig): Promise<void> {
    try {
      await axios.put<void>(`${process.env.REACT_APP_MAIN_URL || ""}user/${id}`, { username, password }, config);
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }

  static async login(username: string, password: string): Promise<{ token: string; user_id: number }> {
    const response: AxiosResponse<{ token: string; user_id: number }> = await axios.post(`${process.env.REACT_APP_IMAGE_URL || ""}login`, {
      username,
      password,
    });
    return response.data;
  }

  static async logout(config: any): Promise<void> {
    await axios.get(`${process.env.REACT_APP_MAIN_URL || ""}user/logout`, config);
  }
  static async get(config: AxiosRequestConfig, page?: number): Promise<{ users: User[]; pages: number }> {
    try {
      let url = `${process.env.REACT_APP_MAIN_URL || ""}user`;
      if (page) {
        url += "?page=" + page;
      }
      const response = await axios.get<{ users: User[]; pages: number }>(url, config);
      return response.data;
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
}

export default UserService;
