import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "./upload.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Pages/Layout";
import NoPage from "./Pages/NoPage";
import Login from "./Pages/Login";
import Users from "./Pages/Users";
import Category from "./Pages/Category";
import AudioScreen from "./Pages/Audio";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/Login" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Users />} />
          <Route path="/Category" element={<Category />} />
          <Route path="/Audio" element={<AudioScreen />} />
        </Route>
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
