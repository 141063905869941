import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Audio } from "../type";
const config = (token: string) => ({
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  },
});
class AudioService {
  static async create(name: string, photo: File, category_id: string, audio: File, token: string): Promise<number> {
    try {
      const formData = new FormData();
      formData.append("photo", photo);
      formData.append("audio", audio);
      formData.append("name", name);
      formData.append("category_id", category_id);
      const response = await axios.post<number>(`${process.env.REACT_APP_MAIN_URL || ""}audio`, formData, config(token));
      return response.data;
    } catch (error) {
      // @ts-ignore
      throw { message: error.response?.data ?? error.message, status: error.response?.status };
    }
  }
  static async delete(id: string, config: AxiosRequestConfig): Promise<void> {
    try {
      await axios.delete<void>(`${process.env.REACT_APP_MAIN_URL || ""}audio/${id}`, config);
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
  static async update(id: string, name: string, category_id: string, token: string, photo?: File, audio?: File): Promise<void> {
    try {
      const formData = new FormData();
      if (photo) formData.append("photo", photo);
      if (audio) formData.append("audio", audio);
      formData.append("name", name);
      formData.append("category_id", category_id);
      await axios.put<void>(`${process.env.REACT_APP_MAIN_URL || ""}audio/${id}`, formData, config(token));
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }

  static async get(config: AxiosRequestConfig): Promise<Audio[]> {
    try {
      let url = `${process.env.REACT_APP_MAIN_URL || ""}audio`;
      const response = await axios.get<Audio[]>(url, config);
      return response.data;
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
}

export default AudioService;
